import utils from "./utils.js";
import "vendor/harvey.js";

utils.initBootstrap();

document.addEventListener("DOMContentLoaded", () => {
    console.log("designsystem ready, using webpack");
});

window.Harvey.attach("screen and (max-width:768px)", {
    on: function () {
        window.$(".hamburger").click(function () {
            $("body").toggleClass("menu-opened");
        });

        window.$("#content-block").click(function () {
            if (window.$(window).width() < 768 || window.$("body").hasClass("menu-opened")) {
                window.$("body").removeClass("menu-opened");
            }
        });
    },
});
