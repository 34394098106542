import "bootstrap-sass/assets/javascripts/bootstrap/transition.js";
import "bootstrap-sass/assets/javascripts/bootstrap/affix.js";
import "bootstrap-sass/assets/javascripts/bootstrap/alert.js";
import "bootstrap-sass/assets/javascripts/bootstrap/dropdown.js";
import "bootstrap-sass/assets/javascripts/bootstrap/tooltip.js";
import "bootstrap-sass/assets/javascripts/bootstrap/modal.js";
import "bootstrap-sass/assets/javascripts/bootstrap/button.js";
import "bootstrap-sass/assets/javascripts/bootstrap/popover.js";
import "bootstrap-sass/assets/javascripts/bootstrap/scrollspy.js";
import "bootstrap-sass/assets/javascripts/bootstrap/collapse.js";
import "bootstrap-sass/assets/javascripts/bootstrap/tab.js";

const initBootstrap = () => {
    // Bootstrap components are imported above
};

export default { initBootstrap };
